<template>
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <div class="text-base leading-tight tracking-wide text-primary text-center">
            {{ formData.email }}
        </div>
        <div class="space-y-2 md:space-y-4">
            <div>
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white-text">Select your Account</label>
                <dropdown-base
                    maxWidth="100%"
                    placeholder="Select Account"
                    :searchable="true"
                    width="100px"
                    minWidth="100%"
                    :options="loginStates.clients"
                    :config="{ label: 'client_name', trackBy: 'id' }"
                    @input="onInput($event, 'clients')"
                    :value="formData.clients"
                    :selectedClient="selectedClient"
                />
            </div>
            <div>
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white-text">
                    <template v-if="isNewClient"> Temporary Password </template>
                    <template v-else> Password </template>
                </label>
                <input type="password" name="password" id="password" placeholder="Enter Password here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required="" v-on="eventHandlers" @input="onInput($event.target.value, 'password')" @keyup.enter="onSubmit('case-dashboard')" />
                <div class="flex items-center justify-end mt-2 text-2xs font-thin">
                    <!-- <span class="text-gray-900 cursor-pointer hover:opacity-80" @click="$emit('on-submit', { component: 'loginEmail' })">Remember Password</span> -->
                    <span :class="[formData.clients && !isNewClient ? 'text-primary hover:opacity-80 cursor-pointer' : 'text-gray-500']" @click="onSubmit('forgotPassword')">Forgot Password?</span>
                </div>
                <div class="text-2xs mt-1 font-thin text-red-500" :class="{ invisible: errors.formData.email.email }">Please enter valid Email Address</div>
            </div>
            <button :disabled="!errors.formData.password.$invalid && !formData.clients" type="submit" class="otp w-full text-white-text bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center items-center" @click="onSubmit('case-dashboard')">
                <Loader class="mr-2" v-if="loadingStates.passLogin" />
                Submit
            </button>
        </div>
    </div>
</template>

<script>
const Loader = () => import("@shared/loader");
import DropdownBase from "@shared/components/dropdown-base";
import VueCookies from "vue-cookies";
import { getLoginInfo} from "./../../services";

export default {
    name: "login-client",
    props: {
        formData: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => {},
        },
        loginStates: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        isNewClient: {
            type: Boolean,
            default: false,
        },
        selectedClient: {
            type:Object,
            default: () => {}
        }
    },
    components: {
        DropdownBase,
        Loader,
    },
    methods: {
        onInput(event, type) {
            // if (type === "clients") {
            //     this.$emit("on-change", { type: "password", value: "" });
            // }
            this.$emit("on-change", { type, value: event });
        },
        onSubmit(type = null) {
            if (type === "forgotPassword" && this.formData.clients && !this.isNewClient) {
                this.$emit("on-submit", { component: "forgotPassword" });
            } else if (type === "case-dashboard") {
                this.$emit("on-submit", { component: "case-dashboard" });
            }
        },
    },
    mounted() {
        getLoginInfo();
        if(this.loginStates && VueCookies.get("next-url")?.includes("#fileManager")) {
            let clientId = VueCookies.get("next-url")?.split('clientid=')[1]?.split('#fileManager')[0];
            let clientName = this.loginStates?.clients?.find(el => el.id == clientId)
            this.selectedClient = clientName
            let type = 'clients'
            if(this.selectedClient)
                this.$emit("on-change", { type, value: this.selectedClient });
        }
    },
    computed: {
        eventHandlers() {
            const result = {}
            const pasteHandler = (e) => {
                e.preventDefault()
            }
            if (this.isNewClient) {
                result.paste = pasteHandler
            }
            return result
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .dropdown {
        //     padding: 20px !important;
        border-color: #DDDDDD;
        background-color: rgba(249, 250, 251, 1);
    }
}
</style>
